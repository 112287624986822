import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import TripleColumnBlock from "@components/tripleColumnBlock"
import PageWrapper from "@components/pageWrapper"

import {
  QuickLinkButton,
} from "@components/buttons"

import {
  LearnMoreButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const IndexPage = () => {
  return (
    <PageLayout>
      <SEO
        title="Webinar Production Atlanta: Evergreen Lead Magnets"
        ogDescription="Discover the power of evergreen webinars, a method designed to educate, engage, and convert prospects to customers. Leverage your expertise repeatedly, enabling sales at scale without geographical and time limitations."
        image="/images/video/webinar-production-atlanta-ga.webp"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
						<h1>Evergreen Webinar Production</h1>
						<p>			
							Free webinars are one of the most engaging sales techniques available online. They are a low barrier of entry for your prospects. Prospects can schedule them at their convenience and participate from home, work or on-the-go.  Evergreen webinars allow you to create webinar content once and use it again and again.
						</p>
						<h2>Educating Your Buyers Through Evergreen Webinars</h2>
						<p>
							The best technique for selling is education. As you educate a prospect, you gain their trust and build your credibility. Offer enough quality educational content to make it worth the prospect's time. But leave them wanting more so they will move from prospect to customer.
						</p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/video/webinar-production-atlanta-ga.webp" className="rounded-image" alt="Webinar Production Atlanta - Websuasion" />
          </div>
        }
      />
	    <PageWrapper color="black">
	      <SingleColumnBlock
	        textPadded
	        text={
	            <div className="double-frame-block">
								<h2>Virtually Expanding Your Sales Efforts</h2>
								<p>
									It's really difficult to build a great business development and <a href="/fractional-marketing-team/">marketing team</a>. That task often falls to business owners like you. You have a career of experience in your field. That experience is difficult to teach a sales team. Haven't you ever wished you could just clone yourself?
								</p>
								<p>
									Evergreen Webinars let you do just that. We design your Webinar around your proven sales methods, case studies, and customer stories. We put you in a position to sell to multiple people at a time multiple times per day. You are no longer bound by location either.
								</p>
								<FormWrapper
				          modal
				          buttonProps={{
				            content: "Schedule An Introductory Call",
				            size: "tiny",
				            icon: "calendar alternate outline",
				          }}
				        >
				          <RequestMoreInfo />
				        </FormWrapper>
	            </div>
	        }
	      />
	    </PageWrapper>
			<PageWrapper color="white">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
	            <img src="/images/video/evergreen-webinar-leads.webp" className="rounded-image" alt="White Label Marketing Agency - Websuasion" />
	          </div>
	        }
	        textRight={
	          <div>
							<h2>Prospect Self-Selection Through Evergreen Webinars</h2>
							<p>
								Marketers often create <a href="/marketing-technology/">lead funnels</a> fed by advertisements and <a href="/content-marketing-strategy/">content marketing techniques</a>. While this can generate lead activity, it isn't a perfect tool. A vast amount of those leads will not be your ideal clients. And filtering out those mismatched contacts can be a drain on your resources. Evergreen Webinars put your ideal prospects in a position to self-select their way to your products and services.
							</p>
							<h2>Evergreen Webinars Become A Sales Pipeline</h2>
							<p>
								Wondering why we keep using the phrase evergreen? Evergreen in this context means that your webinars are always relevant. The webinar content produced can be used over and over again for months and potentially years to come.  Evergreen Webinar production reduces technical glitches, momentary lapses, and tongue-tied speech. We pre-produce seamless webinar content that is automated. You can jump in at any time to answer questions, but the rest of the time you and your staff can monitor progress.
							</p>
	          </div>
	        }
	      />
	      <SingleColumnBlock
	        textPadded
	        text={
	            <div>
								<h2>Evergreen Webinar Platforms</h2>
								<p>
									There are a number of Webinar platforms out there. But only a handful that can handle automated aspects of evergreen webinars. We help you choose the right solution for your needs. Features can include reoccurring webinars, automated chat, notifications, live mode, and more.
								</p>
								<h2>Engaging Evergreen Webinars With Mixed-Media</h2>
								<p>
									PowerPoint presentations can be pretty boring. Evergreen Webinars provide the opportunity for you to keep your prospects engaged to the end of the webinar. Our <a href="/atlanta-video-production/">video production profesionals</a> mix slides, talking head video, interview video, testimonials, motion graphics, and music to create an engaging presentation.
								</p>
	            </div>
	        }
	      />
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
	            <h4>Let's Talk!</h4>
	            <p>"Data. Tech. Team." Those are the words we live by at websuasion. Our services foster growth and scalability for your business - all while maintaining your brand. We are an indispensable ally for you as you navigate online marketing. Let's talk. We'd love to know more about what you do and how we can solve your pain points.</p>
	            <RequestMoreInfo />
	          </div>
	        }
	      />
			</PageWrapper>
      <TripleColumnBlock
        centered
        textPadded
        textLeft={
          <div className="team-block">
            <h3><a href="/atlanta-video-production/">YouTube Series Production</a></h3>
            <p>
              Boost your brand with long format video production. Websuasion creates engaging, optimized YouTube content that elevates visibility and credibility, maximizing ROI.
            </p>
          </div>
        }
        textCenter={
          <div className="team-block">
            <h3><a href="/atlanta-video-production/digital-course-creation/">Digital Course Creation</a></h3>
            <p>
              Transform expertise into new income streams with our digital course creation service. Maximize reach and efficiency, from concept to global client engagement.
            </p>
          </div>
        }
        textRight={
          <div className="team-block">
            <h3><a href="/atlanta-video-production/podcast-production/">Podcast Production</a></h3>
            <p>
              Websuasion crafts engaging podcasts for leaders, turning deep conversations into content that builds authority and connections.
            </p>
          </div>
        }
      />
    </PageLayout>
  )
}

export default IndexPage
